import React, { useState, useEffect, useContext, createContext } from 'react';

import {  getInmuebleSinco ,getInmueblesFull,getVenta} from "../api/SincoApi";
const UserContext = createContext();

export function useUser() {
    return useContext(UserContext);
}

export function UserProvider({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const [Inversionista, setInversionista] = useState();
    const [newUser, setNewUser] = useState();
   
    const isValidJSON = (str) => {
        try {
          JSON.parse(str);
          return true;
        } catch (e) {
          return false;
        }
      };

      const getInitialUser=()=>{
        const stordeuser = localStorage.getItem("usercliente");
        if (stordeuser && isValidJSON(stordeuser)) {
          return JSON.parse(stordeuser);
        }
        return null;
      }

      const getInitialInversionista=()=>{
        const stordinv = localStorage.getItem("userinversion");
        if (stordinv && isValidJSON(stordinv)) {
          return JSON.parse(stordinv);
        }
        return null;
      }


      const getInitialNewUser=()=>{
        const stordnew = localStorage.getItem("newcliente");
        if (stordnew && isValidJSON(stordnew)) {
          return JSON.parse(stordnew);
        }
        return null;
      }
    useEffect(() => {
        setCurrentUser(getInitialUser);
        setInversionista(getInitialInversionista);
        setNewUser(getInitialNewUser);
       
    }, [])

    const UpdateCliente =(user)=>{
        setCurrentUser(user);
        setInversionista(null);
        localStorage.setItem('usercliente',JSON.stringify(user));
    }

    const UpdateInversionista =(user)=>{
        setInversionista(user);
        setCurrentUser(null);
        localStorage.setItem('userinversion',JSON.stringify(user));
    }

    const UpdateNewCliente =(user)=>{
        setNewUser(user);
        if(!user){
            localStorage.removeItem('newcliente');
          
         }else{
            
            localStorage.setItem('newcliente',JSON.stringify(user));
       
         }
    }

    const logout =()=>{
        setCurrentUser(null);
        setInversionista(null);
        localStorage.removeItem('usercliente');
        localStorage.removeItem('userinversion');
        localStorage.removeItem('notificaciones-user');
        localStorage.removeItem('modalInstall');
        return true;
    }

    const getUserActual =()=>{
        return getInitialUser();
    }

    const getInversionista =()=>{
        return getInitialInversionista();
    }


    const getUnicoInmueble=async ()=>{
      const user=getUserActual();
     
      if(getRole()=='propietario'){
      
          if (user?.documento) {
                try {
                    if (user?.documento) {
                        const data = await getInmuebleSinco(user.documento);
                        const inmuebles = await getInmueblesFull(data);
                        if(inmuebles.length==1){
                            localStorage.setItem('inmueblesel', inmuebles[0]?.inmueble?.idAgrupacion);
                          
                            return [data ,inmuebles,true]
                        }
                        return [data ,inmuebles ,false];
                        
                      
                    }
                } catch (error) {
                    console.error('Error al obtener datos:', error);
                    return [ [] , [], false];
    
                }
          }
      }
      if(getRole()=='arrendatario'){
        
          if (user?.inmuebles) {
              try {
                  let dataventas=[];
                  for(let inmu of user?.inmuebles){
                      const data = await getVenta(inmu);
                      
                      if (dataventas.length === 0) {
                
                        
                          dataventas=data; 
                        } else {
                          let inmuebleventa=data?.agrupacionesComprador?.[0];
                      
                          dataventas.agrupacionesComprador.push(inmuebleventa);
                        }
                  
                  }
                  
                  const inmueble = await getInmueblesFull(dataventas);
                  if(inmueble.length==1){
                      localStorage.setItem('inmueblesel', inmueble[0]?.inmueble?.idAgrupacion);
                      return [dataventas, inmueble,true];
                
                    }
                    return [dataventas, inmueble,false];
          
              } catch (error) {
                  console.error('Error al obtener datos:', error);
                      return [[], [] ,false];
  
              }
          }
      }
      return [[] ,[] ,false];
    }

    const getRole =()=>{
        const user=getInitialUser();
        const esPropietario = user?.roles?.includes("ROLE_PROPIETARIO");
        const esArrendador = user?.roles?.includes("ROLE_ARRENDADOR");

        if (esPropietario) {
          return 'propietario';
        } else if(esArrendador) {
            return 'arrendatario';
        }else{   
          return 'otro';
        }
    }


    const value = {
        //getUser,
        currentUser,
        Inversionista,
        getInversionista,
        UpdateCliente,
        UpdateInversionista,
        logout,
        newUser,
        UpdateNewCliente,
        getUnicoInmueble,
        getUserActual,
        getRole,
       
    }

    return (
        <UserContext.Provider value={value}>
            {children}
            </UserContext.Provider>
    )

}