
import { getProyectos,getSettingLlave,getInmuebles, getProyecto, getInmueblesAgrupacion } from './ProyectoApi';
import api from './api';



export const getComprador = async (documento) => {
  try {
    const formData =new FormData();
    formData.append("url", `V3/CBRClientes/API/Compradores/NumeroIdentificacion/`+documento);
    formData.append("method", "GET");
    const compras=await api.post('/proxy',formData);
    return compras?.data;

    } catch (error) {
      console.log(error);
    return[];
  }
}

export const UpdateComprador = async (datos) => {
  try {
    const formData =new FormData();
    formData.append("url", `V3/CBRClientes/API/Compradores/Actualizacion`);
    formData.append("method", "PATCH");
    formData.append("datos",  JSON.stringify(datos));
    const cliente=await api.post('/proxy',formData);
    return cliente?.data;

    } catch (error) {
      console.log(error);
    return[];
  }
}

export const getInmuebleSinco = async (documento) => {
  try {
    const formData =new FormData();
    formData.append("url", `V3/CBRClientes/API/Ventas/NumeroIdentificacion/`+documento);
    formData.append("method", "GET");
    const compras=await api.post('/proxy',formData);
    return compras?.data;

    } catch (error) {
      console.log(error);
    return[];
  }
}


export const getVenta = async (idVenta) => {
  try {
    const formData =new FormData();
    formData.append("url", `V3/CBRClientes/API/Ventas/IdVenta/`+idVenta);
    formData.append("method", "GET");
    const venta=await api.post('/proxy',formData);
    return venta?.data;

    } catch (error) {
      console.log(error);
    return[];
  }
}

export const getProyectosinco = async (idmacro) => {
  try {
    const formData = new FormData();
    formData.append("url", `V3/CBRClientes/API/Proyectos/`+idmacro);
    formData.append("method", "GET");
    const proyectos=await api.post('/proxy',formData);
    return proyectos?.data;

    } catch (error) {
      console.log(error);
    return[];
  }
}


export const getBancoSinco = async (identidad) => {
  try {
    const formData = new FormData();
    formData.append("url", `V3/CBRClientes/API/EntidadesFinancieras/`+identidad);
    formData.append("method", "GET");
    const entidad=await api.post('/proxy',formData);
    return entidad?.data;

    } catch (error) {
      console.log(error);
    return[];
  }
}

export const getBancosSincos = async () => {
  try {
    const formData = new FormData();
    formData.append("url", `V3/CBRClientes/API/EntidadesFinancieras`);
    formData.append("method", "GET");
    const entidad=await api.post('/proxy',formData);
    return entidad?.data;

    } catch (error) {
      console.log(error);
    return[];
  }
}

export const getCarteraVenta = async (venta) => {
  try {
    const formData = new FormData();
    formData.append("url", `V3/CBRClientes/API/EntidadesCarteraEncabezado/IdVenta/`+venta);
    formData.append("method", "GET");
    const entidad=await api.post('/proxy',formData);
    return entidad?.data;

    } catch (error) {
      console.log(error);
    return[];
  }
}

export const  getInmueblesProyecto=async(dataventa)=> {
  const proyectosid=[];
  const inmuebles=[];
  if (!dataventa) {
     
      return;
  }

  const ventas = dataventa?.agrupacionesComprador || [];

  ventas.sort((a, b) => {
    const separacion = a.planPagosAgrupacion?.find((pago) => pago.idConcepto == 0);
    const fechaA = separacion ? new Date(separacion?.fechaPactada).getTime() : 0;
 
    const separacionB = b.planPagosAgrupacion?.find((pago) => pago.idConcepto == 0);
    const fechaB = separacionB ? new Date(separacionB?.fechaPactada).getTime() : 0;
     return fechaB - fechaA; 
  });

  for (const venta of ventas) {
    const idProyecto = venta.idMacroProyecto;

    if (idProyecto && !proyectosid.includes(idProyecto)) {
      proyectosid.push(idProyecto);
    }
              
     inmuebles.push({ venta:{ ...dataventa, agrupacionesComprador: undefined },inmueble: venta, compra:[],proyecto: [],cartera:[], totalventa:0});
        
  }

  let queryString ='visible_interna=1'; 
  queryString+=proyectosid.map((proyecto) => `&id[]=${proyecto}`).join('&');
  queryString+='&groups[]=minimo';
  const data = await getProyectos(queryString);
  let proys= data['hydra:member']

  for (let index = inmuebles.length - 1; index >= 0; index--) {
    const proy = inmuebles[index];
    let  compra= proy?.inmueble?.unidadesAgrupacion?.find((un)=>un.idTipoUnidad==2 || un.idTipoUnidad==1 || un.idTipoUnidad==6);
    if(!compra){
      compra=proy?.inmueble?.unidadesAgrupacion?.find((un)=>un.idTipoUnidad);
    }
    let totalVenta = 0;
    proy?.inmueble?.unidadesAgrupacion?.forEach((unidad) => {
      totalVenta += unidad.valor;
    });
        
    const matchingProy = proys.find((p) => p.id == proy.inmueble?.idMacroProyecto);
   
    if (matchingProy) {
      const formData = new FormData();
      formData.append("url", `V3/CBRClientes/API/EntidadesCarteraEncabezado/IdVenta/${proy.inmueble.idAgrupacion}`);
      formData.append("method", "GET");
      const cartera =await api.post('/proxy',formData);
      proy.proyecto = matchingProy;
      proy.cartera=cartera?.data;
      proy.compra=compra;
      proy.totalventa=totalVenta;
    } else {
      inmuebles.splice(index, 1);
    }
  }
  console.log('inmuebles',inmuebles);
 
  return inmuebles;
}

export const  getInmueblesFull=async(dataventa)=> {
  const proyectosid=[];
  const inmuebles=[];
  if (!dataventa) {
     
      return;
  }

  const ventas = dataventa?.agrupacionesComprador || [];

  ventas.sort((a, b) => {
    const separacion = a.planPagosAgrupacion?.find((pago) => pago.idConcepto == 0);
    const fechaA = separacion ? new Date(separacion?.fechaPactada).getTime() : 0;
 
    const separacionB = b.planPagosAgrupacion?.find((pago) => pago.idConcepto == 0);
    const fechaB = separacionB ? new Date(separacionB?.fechaPactada).getTime() : 0;
     return fechaB - fechaA; 
  });

  for (const venta of ventas) {
    const idProyecto = venta.idMacroProyecto;

    if (idProyecto && !proyectosid.includes(idProyecto)) {
      proyectosid.push(idProyecto);
    }
              
     inmuebles.push({ inmueble: venta, compra:[],proyecto: [], totalventa:0});
        
  }

  let queryString ='visible_interna=1'; 
  queryString+=proyectosid.map((proyecto) => `&id[]=${proyecto}`).join('&');
  queryString+='&groups[]=minimo';
  const data = await getProyectos(queryString);
  let proys= data['hydra:member']

  for (let index = inmuebles.length - 1; index >= 0; index--) {
    const proy = inmuebles[index];
    let  compra= proy?.inmueble?.unidadesAgrupacion?.find((un)=>un.idTipoUnidad==2 || un.idTipoUnidad==1 || un.idTipoUnidad==6);
    if(!compra){
      compra=proy?.inmueble?.unidadesAgrupacion?.find((un)=>un.idTipoUnidad);
    }
 
    const tiposcuotas=await getSettingLlave('conceptos_cuota_inicial_pagos');
 
    const cuotas = proy?.inmueble.planPagosAgrupacion?.filter((plan) => {
      const conceptosPermitidos = tiposcuotas.split(',').map(Number); 
           return conceptosPermitidos.includes(plan.idConcepto);
    });
    const hoy = new Date();
    const mesActual = hoy.getMonth();
    const anioActual = hoy.getFullYear();

      let cuotasActuales=cuotas?.filter(plan => {
      const fechaPactada = new Date(plan.fechaPactada);
     return fechaPactada.getMonth() === mesActual && fechaPactada.getFullYear() === anioActual  && plan.fechaUltimoPago === null && plan.diasMora === 0;
    });
 
  
    if(cuotasActuales?.length==0){
     
      const pagosFaltantes  =cuotas.filter(plan => {
        const fechaPactada = new Date(plan.fechaPactada);
        return plan.fechaUltimoPago === null && fechaPactada < hoy;
      });

      if(pagosFaltantes.length>0){
        cuotasActuales.push(pagosFaltantes[pagosFaltantes.length - 1]);
      }
    }

   
    const cuotasConMora = cuotas.filter(plan => {
      return plan.diasMora > 1 && !cuotasActuales.some(cuotaActual => cuotaActual.concepto === plan.concepto);
    });


   let saldoMora = cuotasConMora?.reduce((acc, plan) => acc + plan.saldo, 0);
      
    let totalVenta = 0;
    proy?.inmueble?.unidadesAgrupacion?.forEach((unidad) => {
      totalVenta += unidad.valor;
    });
    
   
    let recaudo=0;
 
    const pagosrecauda=cuotas?.filter((plan)=>plan.fechaUltimoPago!=null);
    pagosrecauda?.forEach((pago) => {
      recaudo += pago?.valorPagado;
      
    });
    const matchingProy = proys.find((p) => p.id == proy.inmueble?.idMacroProyecto);
   
    if (matchingProy) {    
      const inmglo= await  getInmueblesAgrupacion('agrupacion.sincoid='+compra?.idTipoInmueble)
      proy.proyecto = matchingProy;
      proy.compra=compra;
      proy.cuotas=cuotasActuales;
      proy.recaudo=recaudo;
      proy.saldoAPagar=saldoMora;
      if (cuotasActuales.length > 0) {
        proy.saldoAPagar+= cuotasActuales.reduce((total, cuota) => total + cuota?.valorPactado, 0);
      }
      proy.inmuebleGlobal=inmglo?.['hydra:member']?.[0]?.inmueble;
      proy.totalventa=totalVenta;
    } else {
      inmuebles.splice(index, 1);
    }
  }
  console.log('inmuebles',inmuebles);
 
  return inmuebles;
}

export const  getInmuebleDatos =async(dataventa)=> {
  const inmueble={};
if (!dataventa) {
   
    return;
}

const ventas = dataventa?.agrupacionesComprador || null;
if (!ventas) {
   
  return;
}

  const proy = ventas[0];
  let  compra= proy?.unidadesAgrupacion?.find((un)=>un.idTipoUnidad==2 || un.idTipoUnidad==1 || un.idTipoUnidad==6);
  if(!compra){
    proy?.unidadesAgrupacion?.find((un)=>un.idTipoUnidad);
  }

  const tiposcuotas=await getSettingLlave('conceptos_cuota_inicial_pagos');
  const cuotas = proy?.planPagosAgrupacion?.filter((plan) => {
    const conceptosPermitidos = tiposcuotas.split(',').map(Number); 
         return conceptosPermitidos.includes(plan.idConcepto);
  });
  let cuotainicial= 0;
  cuotas?.forEach((pago) => {
    cuotainicial += pago?.valorPactado;
    
  });

  const hoy = new Date();
    const mesActual = hoy.getMonth();
    const anioActual = hoy.getFullYear();

      let cuotasActuales=cuotas?.filter(plan => {
      const fechaPactada = new Date(plan.fechaPactada);
     return fechaPactada.getMonth() === mesActual && fechaPactada.getFullYear() === anioActual  && plan.fechaUltimoPago === null && plan.diasMora === 0;
    });
  
 
    if(cuotasActuales?.length==0){
     
      const pagosFaltantes  =cuotas.filter(plan => {
        const fechaPactada = new Date(plan.fechaPactada);
        return plan.fechaUltimoPago === null && fechaPactada < hoy;
      });

      if(pagosFaltantes.length>0){
        cuotasActuales.push(pagosFaltantes[pagosFaltantes.length - 1]);
      }
    }

  
  const cuotasConMora = cuotas.filter(plan => {
    return plan.diasMora > 1 && !cuotasActuales.some(cuotaActual => cuotaActual.concepto === plan.concepto);
  });
  let saldoMora = cuotasConMora?.reduce((acc, plan) => acc + plan.saldo, 0);

  let totalVenta =0;
  let recaudo=0;
  proy?.unidadesAgrupacion?.forEach((unidad) => {
    totalVenta += unidad.valor;
    
  });
  const pagosrecauda=cuotas?.filter((plan)=>plan.fechaUltimoPago!=null);
  pagosrecauda?.forEach((pago) => {
    recaudo += pago?.valorPagado;
    
  });
  let saldosmora =cuotasConMora;
 
  const matchingProy= await getProyecto(proy?.idMacroProyecto);
  const inmglo= await getInmueblesAgrupacion('agrupacion.sincoid='+compra?.idTipoInmueble)
  const formData = new FormData();
  formData.append("url", `V3/CBRClientes/API/EntidadesCarteraEncabezado/IdVenta/${proy?.idAgrupacion}`);
  formData.append("method", "GET");
  const cartera =await api.post('/proxy',formData);
   inmueble.venta={ ...dataventa, agrupacionesComprador: undefined }
  inmueble.inmueble=proy;      
  inmueble.proyecto = matchingProy;
  inmueble.compra=compra;
  inmueble.cuotas=cuotasActuales;
  inmueble.recaudo=recaudo;
  inmueble.cartera=cartera?.data;
  inmueble.cuotainicial=cuotainicial;
  inmueble.saldosMora=saldosmora;
  inmueble.saldoAPagar= saldoMora
  if (cuotasActuales.length > 0) {
    inmueble.saldoAPagar+= cuotasActuales.reduce((total, cuota) => total + cuota?.valorPactado, 0);
  }
  inmueble.saldoMora=saldoMora;
  inmueble.inmuebleGlobal=inmglo?.['hydra:member']?.[0]?.inmueble;
  inmueble.totalventa=totalVenta;
    
console.log('inmuebles',inmueble);

return inmueble;
}
