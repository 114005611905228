import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import LinkNext from "next/link";
import Style from "../scss/header.module.scss";
import StyleBtn from "../scss/btn.module.scss";
import Picture from "./PictureComponent";
import { BtnComponent } from "./BtnComponent";
import { Icon } from "@iconify/react";
import { useApi } from "../context/ApiContext";

import {
  showMenu,
  backgroundMenuShow,
  logoShow,
  wpShow,
  btnShow,
  logoHide,
  wpHide,
  btnHide,
  backgroundMenuHide,
  hideMenu,
} from "./headerFunctionComponent";
import { isIOS, isAndroid } from "react-device-detect";

import { scrollToTop } from "./GlobalFunction";
import { Collapse, Modal } from "react-bootstrap";
import { TitleH6Component } from "./TitleH6Component";
import { TitleH3Component } from "./TitleH3Component";
import Stylemodal from "../scss/modal.module.scss";
import { useUser } from "../context/UserContext";
import { getTipoInversionistas } from "../api/InversionistaApi";
import {
  getValidaActivaSeccion,
  getValidaSeccion,
  url_image,
} from "../api/ProyectoApi";
import { getImage } from "../api/galleryApi";
import {
  GoogleAnalytics,
  GoogleTagManager,
  sendGTMEvent,
} from "@next/third-parties/google";
import { getSeo } from "../api/ProyectoApi";
import ReactPixel from "react-facebook-pixel";

export function HeaderComponent() {
  const location = useLocation();
  const blocks = Array.from({ length: 45 });
  const [isClicked, setIsClicked] = useState(false);
  const { getTextoByKey, getTextoBigByKey, getTranslation, currentTextos } =
    useApi();
  //const currentPath = window.location.pathname;
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const [searchValue, setSearchValue] = useState("");
  const [openpopup, setOpenpopup] = useState(false);
  const handleClose = () => setShowSubirresumen(false);
  const [showSubirresumen, setShowSubirresumen] = useState(false);
  const [datosUser, setdatosUser] = useState(false);
  const { newUser, logout } = useUser();
  const [logo, setLogo] = useState([]);
  const [inversionistas, setInversionistas] = useState([]);
  const [buscar, setBuscar] = useState(false);
  const currentUrl = typeof window !== "undefined" ? window.location.href : "";
  const defaultName = "Prodesa";

  function handleClick() {
    setIsClicked(!isClicked);
  }
  function closeMenuMobile() {
    setIsClicked(false);
  }

  useEffect(() => {
    getTipoInversionistas().then((data) => {
      setInversionistas(data);
    });
  }, []);

  useEffect(() => {
    let now = new Date();
    let cacheTime = localStorage.getItem("cache_info");

    console.log(
      now.getTime() - cacheTime,
      now.getTime() - cacheTime > 60000,
      "cache"
    );
    console.log(localStorage.getItem("actualizo-cache"), "actualiza cache");
    if (
      !localStorage.getItem("actualizo-cache") &&
      now.getTime() - cacheTime > 60000
    ) {
      localStorage.setItem("intento-actualiza", 1);
      window.location.reload(true);
    }

    if (!localStorage.getItem("borrar-cache")) {
      localStorage.clear();
      localStorage.setItem("borrar-cache", 1);
    }
  }, [location.pathname]);

  useEffect(() => {
    sendGTMEvent({ event: "conversion_event_signup_3", value: "" });
    ReactPixel.init("1300570970612580");
    ReactPixel.pageView();
  }, [location.pathname]);

  const changeInversion = (id) => {
    console.log(id);
    const targetSection = document.getElementById(id);
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const menuMobile = document.getElementById("menu-mobile");
    const backgrounMenu = document.getElementById("overlay");
    const menu = document.getElementById("wpMobile");
    const logoMenu = document.getElementById("logoMenu");
    const btnClose = document.getElementById("btnClose");
    const time = 300;
    const timeOut = 1000;
    const elements = document.getElementsByClassName(Style.block);
    const elementsArray = Array.from(elements);

    function showBlock() {
      elementsArray.sort(() => Math.random() - 0.5);
      let whiteBlockCount = 0;
      const addVisibleClassWithDelay = (element, index) => {
        setTimeout(() => {
          // 4 clases para los bloques
          if (index % 4 === 0) {
            element.classList.add(Style.visibleBlockTop);
            if (whiteBlockCount < 3) {
              element.classList.add(Style.visibleBlock);
              whiteBlockCount++;
            }
          }
          if (index % 4 === 1) {
            element.classList.add(Style.visibleBlockLeft);
          }
          if (index % 4 === 2) {
            element.classList.add(Style.visibleBlockRight);
          }
          if (index % 4 === 3) {
            element.classList.add(Style.visibleBlockBottom);
          }
          setTimeout(() => {
            element.classList.add(Style.visibleBlockWhite);
          }, 1400);
        }, index * 30);
      };
      setTimeout(() => {
        backgrounMenu.classList.add(Style.bgWhite);
      }, 1400);
      elementsArray.forEach((element, index) => {
        addVisibleClassWithDelay(element, index);
      });
    }

    function hideAll() {
      logoHide(logoMenu);
      wpHide(menu);
      btnHide(btnClose);
      hideMenu(menuMobile);
      backgroundMenuHide(backgrounMenu);
      setTimeout(() => {
        elementsArray.forEach((element) => {
          //hide block
          element.classList.remove(
            Style.visibleBlockTop,
            Style.visibleBlockLeft,
            Style.visibleBlockRight,
            Style.visibleBlockBottom,
            Style.visibleBlockWhite,
            Style.visibleBlock
          );
          backgrounMenu.classList.remove(Style.bgWhite);
        });
      }, timeOut);
    }

    if (isClicked) {
      backgroundMenuShow(backgrounMenu);
      showBlock();
      function runFunctionsInSequence(funcs, time) {
        if (funcs.length === 0) return;
        const [funcToRun, ...remainingFuncs] = funcs;
        setTimeout(() => {
          funcToRun();
          runFunctionsInSequence(remainingFuncs, time);
        }, time);
      }
      const menuFunctions = [
        () => showMenu(menuMobile),
        //() => backgroundMenuShow(backgrounMenu),
        () => logoShow(logoMenu),
        () => wpShow(menu),
        () => btnShow(btnClose),
      ];
      setTimeout(() => {
        runFunctionsInSequence(menuFunctions, time);
      }, 1400);
    } else {
      hideAll();
    }
  }, [isClicked]);

  function actionScrollToTop() {
    scrollToTop();
    closeMenuMobile();
    localStorage.setItem("scrollauntomatico", false);
  }

  const search = (e) => {
    e.preventDefault();
    setBuscar(false);
    const searchParams = new URLSearchParams();
    searchParams.set("search", searchValue);
    if (searchValue.length > 0) {
      const queryString = searchParams.toString();
      window.location.href = `/ResultadosBuscador?${queryString}`;
    }
  };

  const buscaropcion = () => {
    setBuscar(true);
  };

  useEffect(() => {
    setCurrentPath(location.pathname);
    const url = location.pathname.replace(/^\/+/g, "");
    if (url.length) {
      getValidaActivaSeccion("url=" + url).then((data) => {
        if (data.length) {
          if (!data[0].visible) {
            window.location.href = "/";
          }
        }
      });
    }
  }, [location.pathname, currentTextos]);

  useEffect(() => {
    const user = localStorage.getItem("usercliente");
    if (!user) {
      //window.location.href = "/login";
    } else {
      setdatosUser(JSON.parse(user));
    }
  }, [newUser]);

  const CerrarSesion = async () => {
    await logout();
    window.location.href = "/";
  };

  const [seccionValidaProveedores, SetseccionValidaProveedor] = useState(false);

  const [seccionValidaPagos, SetseccionValidapagos] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const resultprovee = await getValidaSeccion(34);
      SetseccionValidaProveedor(resultprovee === 1);

      const resultpago = await getValidaSeccion(30);
      SetseccionValidapagos(resultpago === 1);
    };

    fetchData();
  }, []);

  const [validSections, setValidSections] = useState([]);
  const [validSectionsNosotros, setValidSectionsosotros] = useState([]);

  const [validSectionsOthers, setValidSectionOthers] = useState([]);
  const sectionsToCheck = [
    { id: 10, textKey: "menu-ofertas-vivienda", link: "/vivienda" },
    { id: 14, textKey: "menu-zona-clientes", link: "/login" },
    { id: 4, textKey: "menu-invertir-exterior", link: "/invertir" },
    { id: 29, textKey: "menu-contactenos", link: "/Comunicate" },
  ];

  const additionalLinks = [
    { id: 5, link: "/quienessomos", textKey: "menu-quienes-somos" },
    { id: 16, link: "/gobierno-corporativo", textKey: "menu-corporativo" },
    { id: 13, link: "/sostenibilidad", textKey: "menu-sosteniblidad" },
    { id: 6, link: "/trabajaConNosotros", textKey: "menu-trabaja-nosotros" },
  ];

  const LinksOther = [
    { id: 17, link: "/blog", textKey: "menu-blog" },
    {
      id: 19,
      link: "/inversionista-login",
      textKey: "menu-zona-inversionista",
    },
    { id: 12, textKey: "menu-zona-administradores", link: "/" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const validSectionsData = await Promise.all(
        sectionsToCheck.map(async (section) => {
          const isValid = await getValidaSeccion(section.id);
          return { section, isValid };
        })
      );
      const additionalLinksData = await Promise.all(
        additionalLinks.map(async (section) => {
          const isValid = await getValidaSeccion(section.id);
          return { section, isValid };
        })
      );

      const OthersLinksData = await Promise.all(
        LinksOther.map(async (section) => {
          const isValid = await getValidaSeccion(section.id);
          return { section, isValid };
        })
      );

      setValidSectionOthers(OthersLinksData.filter(({ isValid }) => isValid));

      setValidSectionsosotros(
        additionalLinksData.filter(({ isValid }) => isValid)
      );
      setValidSections(validSectionsData.filter(({ isValid }) => isValid));
    };

    fetchData();
  }, []);

  useEffect(() => {
    Promise.all([getImage("logo")])
      .then(([logoData]) => {
        setLogo(logoData);
      })
      .catch((error) => {
        console.error("Error al cargar datos:", error);
      });
  }, []);

  const [meta, setMeta] = useState(null);

  useEffect(() => {
    async function fetchMeta() {
      const seoData = await getSeo(location.pathname);

      const newMeta = {
        title: seoData?.titulo || defaultName,
        description: seoData?.descripcion || defaultName,
        keywords: seoData.keywords
          ? seoData?.keywords?.split?.(",")
          : defaultName,
        icons: {
          icon: "/FAVICON-PRODESA1.png",
        },
        openGraph: {
          title: seoData?.titulo || defaultName,
          description: seoData?.descripcion || defaultName,
          url: currentUrl,
          images: [
            {
              url: seoData?.image?.image || "",
              width: 1200,
              height: 630,
              alt: defaultName,
            },
          ],
        },
        alternates: {
          canonical: seoData?.canonical || currentUrl,
        },
      };
      console.log("newmeta", newMeta);

      //setMeta(newMeta);
      updateHead(newMeta);
    }

    fetchMeta();
  }, [location.pathname]);

  const updateHead = (meta) => {
    document.title = meta.title;
    let descriptionMeta = document.querySelector('meta[name="description"]');
    if (descriptionMeta) {
      descriptionMeta.setAttribute("content", meta.description);
    } else {
      descriptionMeta = document.createElement("meta");
      descriptionMeta.name = "description";
      descriptionMeta.content = meta.description;
      document.head.appendChild(descriptionMeta);
    }

    let keywordsMeta = document.querySelector('meta[name="keywords"]');
    if (keywordsMeta) {
      keywordsMeta.setAttribute("content", meta.keywords);
    } else {
      keywordsMeta = document.createElement("meta");
      keywordsMeta.name = "keywords";
      keywordsMeta.content = meta.keywords;
      document.head.appendChild(keywordsMeta);
    }

    let canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.setAttribute("href", meta.alternates.canonical);
    } else {
      canonicalLink = document.createElement("link");
      canonicalLink.rel = "canonical";
      canonicalLink.href = meta.alternates.canonical;
      document.head.appendChild(canonicalLink);
    }

    let ogTitleMeta = document.querySelector('meta[property="og:title"]');
    if (ogTitleMeta) {
      ogTitleMeta.setAttribute("content", meta.openGraph.title);
    } else {
      ogTitleMeta = document.createElement("meta");
      ogTitleMeta.setAttribute("property", "og:title");
      ogTitleMeta.content = meta.openGraph.title;
      document.head.appendChild(ogTitleMeta);
    }

    let ogDescriptionMeta = document.querySelector(
      'meta[property="og:description"]'
    );
    if (ogDescriptionMeta) {
      ogDescriptionMeta.setAttribute("content", meta.openGraph.description);
    } else {
      ogDescriptionMeta = document.createElement("meta");
      ogDescriptionMeta.setAttribute("property", "og:description");
      ogDescriptionMeta.content = meta.openGraph.description;
      document.head.appendChild(ogDescriptionMeta);
    }

    let ogUrlMeta = document.querySelector('meta[property="og:url"]');
    if (ogUrlMeta) {
      ogUrlMeta.setAttribute("content", meta.openGraph.url);
    } else {
      ogUrlMeta = document.createElement("meta");
      ogUrlMeta.setAttribute("property", "og:url");
      ogUrlMeta.content = meta.openGraph.url;
      document.head.appendChild(ogUrlMeta);
    }

    let ogImageMeta = document.querySelector('meta[property="og:image"]');
    if (ogImageMeta) {
      ogImageMeta.setAttribute("content", meta.openGraph.images[0].url);
    } else {
      ogImageMeta = document.createElement("meta");
      ogImageMeta.setAttribute("property", "og:image");
      ogImageMeta.content = meta.openGraph.images[0].url;
      document.head.appendChild(ogImageMeta);
    }

    let ogImageWidthMeta = document.querySelector(
      'meta[property="og:image:width"]'
    );
    if (ogImageWidthMeta) {
      ogImageWidthMeta.setAttribute("content", meta.openGraph.images[0].width);
    } else {
      ogImageWidthMeta = document.createElement("meta");
      ogImageWidthMeta.setAttribute("property", "og:image:width");
      ogImageWidthMeta.content = meta.openGraph.images[0].width;
      document.head.appendChild(ogImageWidthMeta);
    }

    let ogImageHeightMeta = document.querySelector(
      'meta[property="og:image:height"]'
    );
    if (ogImageHeightMeta) {
      ogImageHeightMeta.setAttribute(
        "content",
        meta.openGraph.images[0].height
      );
    } else {
      ogImageHeightMeta = document.createElement("meta");
      ogImageHeightMeta.setAttribute("property", "og:image:height");
      ogImageHeightMeta.content = meta.openGraph.images[0].height;
      document.head.appendChild(ogImageHeightMeta);
    }
  };

  return (
    <>
      <header className={`${Style.header}`}>
        <GoogleTagManager gtmId="GTM-PQ74TSF" />
        <GoogleAnalytics gaId="G-F6XC2SJ95S" />
        {currentPath !== "/inversionistas" ? (
          <div className="bg-gris pe-md-4">
            <ul
              className={`d-flex flex-wrap align-items-center justify-content-center justify-content-lg-end  ${Style.subNav}`}
            >
              <li>
                <div className="dropdown">
                  <a
                    href="/"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    className={Style.subNavItem}
                  >
                    {getTextoByKey("menu-nosotros")?.valor}
                  </a>
                  <ul className={`dropdown-menu ${Style.dropSubNav}`}>
                    {validSectionsNosotros.map(({ section }, index) => (
                      <li key={index}>
                        <Link
                          to={section?.link}
                          className={Style.subNavItem}
                          onClick={actionScrollToTop}
                        >
                          {getTextoByKey(section?.textKey)?.valor}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>

              {validSectionsOthers.map(({ section }, index) => (
                <li key={index}>
                  {getTextoByKey(section?.textKey)?.valor == "Blog" ? (
                    <a
                      href={getTextoByKey(section?.textKey)?.link}
                      id={section.textKey}
                      className={Style.subNavItem}
                      onClick={actionScrollToTop}
                    >
                      {getTextoByKey(section?.textKey)?.valor}
                    </a>
                  ) : (
                    <Link
                      to={getTextoByKey(section?.textKey)?.link}
                      id={`${section.textKey}`}
                      className={Style.subNavItem}
                      onClick={actionScrollToTop}
                    >
                      {getTextoByKey(section?.textKey)?.valor}
                    </Link>
                  )}
                </li>
              ))}
              {seccionValidaProveedores && (
                <li>
                  <Link
                    to={getTextoByKey("menu-zona-proveedores")?.link}
                    target="_blank"
                    className={`pe-md-0 ${Style.subNavItem}`}
                    onClick={actionScrollToTop}
                  >
                    {getTextoByKey("menu-zona-proveedores")?.valor}
                  </Link>
                </li>
              )}
            </ul>
          </div>
        ) : null}
        <div
          className={`${Style.reverse} d-flex justify-content-between align-items-center px-2 px-md-5`}
        >
          <a href="/" className={`${Style.child_menu} ${Style.logo}`}>
            <Picture
              src={url_image + logo?.image}
              alt="Constructora Prodesa"
              title="Constructora Prodesa"
              styleGroup="w-100"
            />
          </a>

          <button
            className={`${Style.child_menu} ${Style.btnMenu}`}
            onClick={handleClick}
          >
            <Icon icon="gg:menu-round" />
          </button>
          <div
            className={`${Style.wpNav} ${
              currentPath === "/inversionistas" && Style.inversionista
            }`}
            id="menu-mobile"
          >
            <div className="d-flex justify-content-center d-sm-none">
              <a href="/" className={Style.logo} id="logoMenu">
                <Picture
                  src={url_image + logo?.image}
                  alt="Prodesa"
                  styleGroup="w-100"
                />
              </a>
            </div>
            <hr className={Style.hr} />
            <nav
              className={`me-lg-4 ${
                currentPath === "/inversionistas"
                  ? Style.navbarInversionista
                  : Style.navbar
              }`}
              id="wpMobile"
            >
              {currentPath === "/inversionistas" ? (
                <ul className={Style.nav}>
                  {inversionistas.map((item, index) => (
                    <li className={Style.navItem} key={index}>
                      <NavLink
                        to={"#"}
                        onClick={() => changeInversion("collapse" + item.id)}
                        className={Style.navLink}
                        exact="true"
                      >
                        {getTranslation(item, "titulo")}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              ) : null}
              {currentPath !== "/inversionistas" ? (
                <ul className={Style.nav}>
                  {validSections.map(({ section }, index) => (
                    <li key={index} className={Style.navItem}>
                      <NavLink
                        to={section?.link}
                        className={Style.navLink}
                        exact="true"
                        onClick={actionScrollToTop}
                        id={`${section.textKey}`}
                      >
                        {getTextoByKey(section.textKey)?.valor}
                      </NavLink>
                    </li>
                  ))}
                  {(isAndroid || isIOS) && seccionValidaPagos ? (
                    <li className={Style.navItem}>
                      <NavLink
                        to="/PayOnline"
                        className={Style.navLink}
                        exact="true"
                        onClick={actionScrollToTop}
                        id="btn-pagos"
                      >
                        {getTextoByKey("menu-realizar-pago")?.valor}
                      </NavLink>
                    </li>
                  ) : null}
                </ul>
              ) : null}
            </nav>
            <hr className={Style.hr} />
            {currentPath !== "/inversionistas" ? (
              <div className="d-md-flex align-items-stretch">
                <form
                  className={`mb-0 mb-md-0 me-md-2 me-lg-0 ${Style.searcher} ${
                    buscar ? Style.active : ""
                  } class-formBuscar`}
                  onSubmit={search}
                  id="id-formBuscar"
                >
                  <button
                    className={`${Style.btnSearch} class-btnBuscar`}
                    type="submit"
                    id="id-btnBuscar"
                  >
                    <Icon icon="bi:search" />
                  </button>
                  <input
                    className={`${Style.input} class-inputBuscar`}
                    type="search"
                    onChange={(e) => setSearchValue(e.target.value)}
                    value={searchValue}
                    placeholder="Buscar"
                    aria-label="Search"
                    id="id-inputBuscar"
                  />
                </form>

                <button
                  className={`${Style.btnSearchactive} class-btnBuscar ${
                    !buscar ? "d-block" : "d-none"
                  }`}
                  type="button"
                  id="id-btnBuscar"
                  onClick={buscaropcion}
                >
                  <Icon icon="bi:search" />
                </button>

                <div onClick={actionScrollToTop}>
                  {seccionValidaPagos && (
                    <BtnComponent
                      to="/PayOnline"
                      styleGroup={`ms-lg-4 py-2  ${
                        isIOS || isAndroid
                          ? StyleBtn.btn
                          : StyleBtn.btnSecundaryv2
                      } ${StyleBtn.btnSmall} ${StyleBtn.btnFlex}`}
                    >
                      {getTextoByKey("menu-realizar-pago")?.valor}
                    </BtnComponent>
                  )}
                </div>
              </div>
            ) : null}
            <button
              className={Style.btnClose}
              id="btnClose"
              onClick={closeMenuMobile}
            >
              <Icon icon="zondicons:close-outline" />
            </button>
          </div>
          {currentPath === "/inversionistas" ? (
            <div className={`${Style.caja_menu} ${Style.child_menu}`}>
              <div
                onClick={() => {
                  setOpenpopup(!openpopup);
                }}
              >
                <Picture
                  src={"/img/icon/profileicon.png"}
                  alt="Prodesa"
                  styleGroup={` w-auto ${Style.iconImg}`}
                />
              </div>
              <Collapse in={openpopup} className={`${Style.desplegable_menu}`}>
                <div id="example-collapse-text">
                  <div className="d-flex flex-column">
                    <TitleH6Component
                      styleGroup={`WorkSans text-start color-naranja fw700 mb-0 pb-2 ${Style.text_menu}`}
                    >
                      {datosUser?.nombre} {datosUser?.apellido}{" "}
                      {datosUser?.apellidodos}
                    </TitleH6Component>
                    <NavLink
                      to={"/inversionista-perfil"}
                      className={`color-gris fw400 roboto ${Style.navLink} p-0 pb-2`}
                      exact="true"
                      onClick={actionScrollToTop}
                    >
                      Perfil
                    </NavLink>
                    <button
                      type="button"
                      className={`${Style.btndesplegable} text-start color-gris fw400 roboto ${Style.navLink} p-0  `}
                      onClick={() => {
                        setShowSubirresumen(true);
                      }}
                    >
                      <span className={`${Style.text_menu}`}>
                        Cerrar sesión
                      </span>
                    </button>
                  </div>
                </div>
              </Collapse>
            </div>
          ) : null}
          <div className={Style.overlay} id="overlay">
            {blocks.map((_, index) => (
              <span className={Style.block} key={index}></span>
            ))}
          </div>
        </div>

        <section>
          <Modal
            show={showSubirresumen}
            onHide={handleClose}
            className=" modal-pagos d-flex justify-content-center align-items-center "
          >
            <Modal.Body>
              <div className={` mb-0 ${Stylemodal.iconClose} `}>
                <Icon
                  icon="mdi:close"
                  onClick={() => {
                    handleClose();
                  }}
                />
              </div>
              <TitleH3Component styleGroup="mb-0 pb-3  text-center color-gris   fw600 WorkSans">
                ¿Estás seguro que deseas cerrar sesión?
              </TitleH3Component>
              <div className="pt-2 d-flex flex-column align-items-center">
                <button
                  type="button"
                  onClick={() => {
                    CerrarSesion();
                  }}
                  className={`${StyleBtn.btn} w-100`}
                >
                  Cerrar sesión
                </button>
                <button
                  type="button"
                  onClick={() => {
                    handleClose();
                  }}
                  className={`${StyleBtn.btn} ${StyleBtn.btnblanco} w-100 mt-3 `}
                >
                  Cancelar
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </section>
      </header>
    </>
  );
}
