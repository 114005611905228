import { Fragment } from "react";
import Style from "../scss/btn.module.scss";
import { NavLink } from "react-router-dom";
import { scrollToTop } from "./GlobalFunction";
import Link from "next/link";

export function BtnComponent(props) {
  function actionScrollToTop() {
    if (props.to != null && props.to != undefined && props.to != "") {
      if (props.clickeable) {
        if (props?.target) {
          window.open(props.to, "_blank");
          return;
        } else {
          window.location.href = props.to;
        }
      }
    } else if (props.onClick) {
      props.onClick();
    } else {
      scrollToTop();
    }
  }

  return (
    <Fragment>
      {props.clickeable ? (
        <div
          className={`${Style.btn} ${props.styleGroup}`}
          onClick={actionScrollToTop}
        >
          <span className={Style.wpChild}>{props.children}</span>
          <span className={Style.loadingContainer}>
            <span className={Style.dot}></span>
            <span className={Style.dot}></span>
            <span className={Style.dot}></span>
          </span>
        </div>
      ) : (
        <Fragment>
          <NavLink
            to={props.to}
            className={`${Style.btn} ${props.styleGroup}`}
            onClick={actionScrollToTop}
            id={props.id}
          >
            <span className={Style.wpChild}>{props.children}</span>
            <span className={Style.loadingContainer}>
              <span className={Style.dot}></span>
              <span className={Style.dot}></span>
              <span className={Style.dot}></span>
            </span>
          </NavLink>
        </Fragment>
      )}
    </Fragment>
  );
}

export function Button({ className = "", to, children }) {
  return (
    <>
      {to && (
        <Link className={`${Style.btn} ${className}`} href={to}>
          <span className={Style.wpChild}>{children}</span>
          <span className={Style.loadingContainer}>
            <span className={Style.dot}></span>
            <span className={Style.dot}></span>
            <span className={Style.dot}></span>
          </span>
        </Link>
      )}
    </>
  );
}
