import api from './api';

export const getCertificacionesSostenibles = async (query) => {
    try {
      const response = await api.get(`/certificacions?${query}&order[orden]=asc`);
         return response.data['hydra:member'];
    } catch (error) {
      console.error(error);
      return[];
    }
  }

  export const getOficinas = async (query=null) => {
    try {
      const response = await api.get(`/oficinas?${query ? query :''}`);
         return response.data['hydra:member'];
    } catch (error) {
      console.error(error);
      return[];
    }
  }