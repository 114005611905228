import Link from "next/link";
import { getTextTranstalation, getTranslation } from "@integrations/prodesa/util";
import { TextoTranslationType, TextoType } from "@integrations/prodesa/definitions";
import { H3 } from "@ui/general/footer/Texts";
import textStyles from "@scss/text.module.scss";
import styles from "@scss/footer.module.scss";

export default function SuperFooter({ texts }: { texts: TextoType[] }) {
    const superfooterColumn1Title = getTextTranstalation(texts, 'superfooter-columna-1-titulo')?.valor;
    const superfooterColumn2Title = getTextTranstalation(texts, 'superfooter-columna-2-titulo')?.valor;
    const superfooterColumn3Title = getTextTranstalation(texts, 'superfooter-columna-3-titulo')?.valor;

    const superfooterColumn2Title1 = getTextTranstalation(texts, 'superfooter-columna-2-titulo-1')?.valor;
    const superfooterColumn2Title2 = getTextTranstalation(texts, 'superfooter-columna-2-titulo-2')?.valor;
    const superfooterColumn2Title3 = getTextTranstalation(texts, 'superfooter-columna-2-titulo-3')?.valor;
    const superfooterColumn2Title4 = getTextTranstalation(texts, 'superfooter-columna-2-titulo-4')?.valor;

    const superfooterColumn1Links = getColumnItemTranslations('superfooter-columna-1-item', texts);
    const superfooterColumn2Section1Links = getColumnItemTranslations('superfooter-columna-2-seccion-1-item', texts);
    const superfooterColumn2Section2Links = getColumnItemTranslations('superfooter-columna-2-seccion-2-item', texts);
    const superfooterColumn2Section3Links = getColumnItemTranslations('superfooter-columna-2-seccion-3-item', texts);
    const superfooterColumn2Section4Links = getColumnItemTranslations('superfooter-columna-2-seccion-4-item', texts);
    const superfooterColumn3Links = getColumnItemTranslations('superfooter-columna-3-item', texts);
    return (
        <div className='row'>
            <div className="col-12 col-md-6 col-lg-3 text-center text-md-start d-none d-lg-block .d-xl-block">
            </div>
            <div className="col-12 col-md-6 col-lg-3 text-center text-md-start">
                <H3>{superfooterColumn1Title}</H3>
                <ul className={`WorkSans fw400 ${styles.list}`}>
                    {superfooterColumn1Links.map(({ valor, link }, index) => (
                        <li key={index}>
                            <a href={`${link}`} className={`${textStyles.p} ${textStyles.textosApis} color-gris mb-2`} aria-label="Superfooter link">
                                {valor}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="col-12 col-md-6 col-lg-3 text-center text-md-start">
                <H3>{superfooterColumn2Title}</H3>
                <H3>{superfooterColumn2Title1}</H3>
                <ul className={`WorkSans fw400 ${styles.list}`}>
                    {superfooterColumn2Section1Links.map(({ valor, link }, index) => (
                        <li key={index}>
                            <a href={`${link}`} className={`${textStyles.p} ${textStyles.textosApis} color-gris mb-2`} aria-label="Superfooter link">
                                {valor}
                            </a>
                        </li>
                    ))}
                </ul>
                <H3 className="mt-2">{superfooterColumn2Title2}</H3>
                <ul className={`WorkSans fw400 ${styles.list}`}>
                    {superfooterColumn2Section2Links.map(({ valor, link }, index) => (
                        <li key={index}>
                            <a href={`${link}`} className={`${textStyles.p} ${textStyles.textosApis} color-gris mb-2`} aria-label="Superfooter link">
                                {valor}
                            </a>
                        </li>
                    ))}
                </ul>
                <H3 className="mt-2">{superfooterColumn2Title3}</H3>
                <ul className={`WorkSans fw400 ${styles.list}`}>
                    {superfooterColumn2Section3Links.map(({ valor, link }, index) => (
                        <li key={index}>
                            <a href={`${link}`} className={`${textStyles.p} ${textStyles.textosApis} color-gris mb-2`} aria-label="Superfooter link">
                                {valor}
                            </a>
                        </li>
                    ))}
                </ul>
                <H3 className="mt-2">{superfooterColumn2Title4}</H3>
                <ul className={`WorkSans fw400 ${styles.list} mb-4`}>
                    {superfooterColumn2Section4Links.map(({ valor, link }, index) => (
                        <li key={index}>
                            <a href={`${link}`} className={`${textStyles.p} ${textStyles.textosApis} color-gris mb-2`} aria-label="Superfooter link">
                                {valor}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="col-12 col-md-6 col-lg-3 text-center text-md-start">
                <H3>{superfooterColumn3Title}</H3>
                <ul className={`WorkSans fw400 ${styles.list}`}>
                    {superfooterColumn3Links.map(({ valor, link }, index) => (
                        <li key={index}>
                            <a href={`${link}`} className={`${textStyles.p} ${textStyles.textosApis} color-gris mb-2`} aria-label="Superfooter link">
                                {valor}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

function getColumnItemTranslations(columnId, texts: TextoType[]): TextoTranslationType[] {
    const items = texts.filter(({ llave }) => llave?.includes(columnId));
    return items.map((item) => {
        return getTranslation(item.translations);
    });
}