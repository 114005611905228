import { Link } from "react-router-dom";
import Style from "../scss/footer.module.scss";
import { TitleH3Component } from "./TitleH3Component";
import { ParagraphComponent } from "./ParagraphComponent";
import { Icon } from "@iconify/react";
import { scrollToTop } from "./GlobalFunction";
import { Fragment, useEffect, useState } from "react";
import { getGallery, getImage } from "../api/galleryApi";
import { useApi } from "../context/ApiContext";
import { getProyectos, url_image, getValidaSeccion } from "../api/ProyectoApi";
import { getOficinas } from "../api/SostenibilidadApi";
import Picture from "./PictureComponent";
import SuperFooter from "@/app/ui/general/footer/SuperFooter";

export function FooterComponent() {
  const [logoAndroid, setLogoAndroid] = useState([]);
  const [logoApple, setLogoApple] = useState([]);
  const [logo, setLogo] = useState([]);
  const [redes, setRedes] = useState([]);
  const [oficinas, setOficinas] = useState([]);
  const [nosotros, setNosotros] = useState([]);
  const [accesos, setAccesos] = useState([]);

  const [politicas, setPoliticas] = useState([]);
  const { getTextoByKey, getTranslation, currentTextos } = useApi();

  function actionScrollToTop() {
    scrollToTop();
  }

  useEffect(() => {
    const fetchData = async () => {
      const redesData = await getGallery("redes-footer");
      setRedes(redesData);

      const nosotrosData = await getGallery("textos-footer-nosotros");
      const nosotros = [];
      for (const sec of nosotrosData) {
        if (sec?.icono) {
          const isValid = await getValidaSeccion(sec.icono);

          if (isValid === 1) {
            nosotros.push(sec);
          }
        } else {
          nosotros.push(sec);
        }
      }
      setNosotros(nosotros);

      const politicasData = await getGallery("textos-footer-politicas");
      const politics = [];

      for (const sec of politicasData) {
        if (sec?.icono) {
          const isValid = await getValidaSeccion(sec.icono);

          if (isValid === 1) {
            politics.push(sec);
          }
        } else {
          politics.push(sec);
        }
      }
      setPoliticas(politics);

      const accesosData = await getGallery("textos-footer-accesos-rapidos");
      const acceso = [];

      for (const sec of accesosData) {
        if (sec?.icono) {
          const isValid = await getValidaSeccion(sec.icono);

          if (isValid === 1) {
            acceso.push(sec);
          }
        } else {
          acceso.push(sec);
        }
      }

      setAccesos(acceso);

      const oficinasData = await getOficinas();
      setOficinas(oficinasData);

      const logoAndroidData = await getImage("logo-android");
      setLogoAndroid(logoAndroidData);

      const logoAppleData = await getImage("logo-apple");
      setLogoApple(logoAppleData);

      const logoData = await getImage("logo");
      setLogo(logoData);
    };

    fetchData();
  }, []);

  return (
    <footer>
      <div className={`py-4 ${Style.footer}`} id="footer">
        <div className="container-fluid px-5">
          <SuperFooter texts={currentTextos} />
          <div className="row">
            <div className="col-12 col-md-6 col-lg-3 text-center text-md-start">
              <div className="mb-4">
                <div className={Style.contentLogo}>
                  <img
                    src={url_image + logo?.image}
                    alt="Prodesa"
                    className={Style.logo}
                  />
                </div>
              </div>
              <TitleH3Component styleGroup="color-naranja mb-3 mb-lg-3 fw600 WorkSans">
                Descarga nuestra App
              </TitleH3Component>
              <div className="d-flex mb-3 align-items-center justify-content-center justify-content-md-start">
                <Link to={getTranslation(logoApple, "link")}>
                  <img
                    src={url_image + logoApple?.image}
                    alt="App Store"
                    className={`me-2 ${Style.iconDescarga}`}
                  />
                </Link>
                <Link to={getTranslation(logoAndroid, "link")}>
                  <img
                    src={url_image + logoAndroid?.image}
                    alt="Google Play"
                    className={` ${Style.iconDescarga}`}
                  />
                </Link>
              </div>
              <TitleH3Component styleGroup="color-naranja mb-3 mb-lg-3 fw600 WorkSans">
                Síguenos en
              </TitleH3Component>
              <div>
                {redes.map((item, index) => (
                  <Link
                    key={index}
                    target="_blank"
                    to={getTranslation(item, "link")}
                    className={`me-2 `}
                  >
                    {/*<Icon icon={getTranslation(item, 'boton')} className={Style.socialLink} />*/}
                    <Picture
                      src={url_image + item?.image}
                      alt="redes"
                      styleGroup={`w-auto mb-2 ${Style.hvimg}`}
                    />
                  </Link>
                ))}
                {/*<Link to="/" className="me-2">
                                    <Icon icon="ant-design:facebook-outlined" className={Style.socialLink}/>
                                </Link>
                                <Link to="/" className="me-2">
                                    <Icon icon="ant-design:linkedin-outlined" className={Style.socialLink}/>
                                </Link>
                                <Link to="/" className="me-2">
                                    <Icon icon="la:twitter-square" className={Style.socialLink}/>
                                </Link>
                                <Link to="/" className="me-2">
                                    <Icon icon="mingcute:youtube-line" className={Style.socialLink}/>
                            </Link>*/}
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 text-center text-md-start">
              <TitleH3Component styleGroup="color-naranja mb-3 mb-lg-3 fw600 WorkSans">
                {getTextoByKey("texto-footer-titulo-nosotros")?.valor}
              </TitleH3Component>
              <ul className={`${Style.list} fw400 WorkSans`}>
                {nosotros.map((item, index) => (
                  <li key={index}>
                    {getTranslation(item,"titulo") === "Blog" ? (
                      <a
                        href={getTranslation(item, "link")}
                        id={getTranslation(item, "titulo")}
                        className="link-banner"
                        onClick={actionScrollToTop}
                      >
                        <ParagraphComponent styleGroup="color-gris mb-2">
                          {getTranslation(item, "titulo")}
                        </ParagraphComponent>
                      </a>
                    ) : (
                      <Link
                        to={getTranslation(item, "link")}
                        onClick={actionScrollToTop}
                        className="link-banner"
                      >
                        <ParagraphComponent styleGroup="color-gris mb-2">
                          {getTranslation(item, "titulo")}
                        </ParagraphComponent>
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
              <ParagraphComponent styleGroup="color-gris mb-0 pt-2 text-uppercase fw400 WorkSans">
                {getTextoByKey("texto-footer-titulo-sagrilaft")?.valor}
              </ParagraphComponent>
              <ul className={`${Style.list} fw400 WorkSans`}>
                {politicas.map((item, index) => (
                  <li key={index}>
                    <Link
                      to={getTranslation(item, "link")}
                      onClick={actionScrollToTop}
                      className="link-banner"
                    >
                      <ParagraphComponent styleGroup="color-gris mb-0">
                        <small>{getTranslation(item, "titulo")}</small>
                      </ParagraphComponent>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-12 col-md-6 col-lg-3 text-center text-md-start">
              <TitleH3Component styleGroup="color-naranja mb-3 mb-lg-3 fw600 WorkSans">
                {getTextoByKey("texto-footer-titulo-accesos")?.valor}
              </TitleH3Component>
              <ul className={`${Style.list} fw400 WorkSans`}>
                {accesos.map((item, index) => (
                  <li key={index}>
                    <Link
                      to={getTranslation(item, "link")}
                      target={
                        getTranslation(item, "titulo") === "Zona proveedores"
                          ? "_blank"
                          : "_self"
                      }
                      onClick={actionScrollToTop}
                      className="link-banner"
                    >
                      <ParagraphComponent styleGroup="color-gris mb-2">
                        {getTranslation(item, "titulo")}
                      </ParagraphComponent>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-12 col-md-6 col-lg-3 text-center text-md-start">
              <TitleH3Component styleGroup="color-naranja mb-3 mb-lg-3 fw600 WorkSans">
                {getTextoByKey("texto-footer-titulo-contacto")?.valor}
              </TitleH3Component>
              {oficinas.map((item, index) => (
                <ParagraphComponent
                  styleGroup="color-gris mb-2 fw400 WorkSans"
                  key={index}
                >
                  {item?.nombre}
                  <br />
                  {item?.direccion}
                </ParagraphComponent>
              ))}
              {/*<ParagraphComponent styleGroup="color-white mb-2">
                                Dirección 2<br/>
                                Lorem ipsum dolor<br/>
                                Cl 1 #2-3 Piso 4 Barrio, Ciudad
                            </ParagraphComponent>*/}
              <ParagraphComponent styleGroup="color-gris mb-2 fw400 WorkSans">
                Teléfono:
                <br />
                {oficinas.map((item, index) => (
                  <Fragment key={index}>
                    <Link className="color-gris link-banner" key={index}>
                      {item?.telefono}
                    </Link>
                    <br />
                  </Fragment>
                ))}
              </ParagraphComponent>
              <ParagraphComponent styleGroup="color-gris mb-4 mb-md-2 fw400 WorkSans">
                Correo:
                <br />
                <Link
                  to={getTextoByKey("email-footer")?.link}
                  className="color-gris link-banner"
                >
                  {getTextoByKey("email-footer")?.valor}
                </Link>
              </ParagraphComponent>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <hr className={Style.hr}></hr>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <ParagraphComponent styleGroup="color-gris mb-2 fw400 WorkSans">
                <small>{getTextoByKey("texto-footer-copyright")?.valor}</small>
              </ParagraphComponent>
            </div>
            <div className="col-6 text-end">
              <Link to="https://iridian.co/" target="_blank">
                <ParagraphComponent styleGroup="color-gris mb-2">
                  <small>Powered by Iridian</small>
                </ParagraphComponent>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
