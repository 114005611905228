import api from './api';

export const getTipoInversionistas = async () => {
    try {
      const response = await api.get(`/inversionista_archivos?visible=1&order[orden]=asc`);
         return response.data['hydra:member'];
    } catch (error) {
      console.error(error);
      return[];
    }
  }


  export const getArchivos = async (query) => {
    try {
      const response = await api.get(`/file_inversions?visible=1${query}&order[fecha]=asc`);
         return response.data['hydra:member'];
    } catch (error) {
      console.error(error);
      return[];
    }
  }
